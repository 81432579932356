import * as React from 'react'
import { useState, useEffect } from 'react'
import RemoveFieldsButton from '../shared/RemoveFieldsButton'
import { useRemoveShipmentMutation, useShipmentsQuery } from '../../hooks/queries/shipments'

type ShipmentShape = {
  id: number
  description: string
  approved: boolean
  price: string
}

type ShipmentFieldsProps = {
  jobnumberId: number
  shipmentFields: { [key: string]: ShipmentShape }
  setShipmentFields: (shipmentFields: any) => void
  fieldNameFn: (index: number, field: string) => string
}

export default function ShipmentFields(props: ShipmentFieldsProps) {
  const { jobnumberId, shipmentFields, setShipmentFields, fieldNameFn } = props

  const { data: shipmentsData, isLoading: shipmentsLoading } = useShipmentsQuery(jobnumberId)
  const removeShipmentMutation = useRemoveShipmentMutation()
  const removeShipment = shipmentId => {
    removeShipmentMutation.mutate(shipmentId)
  }

  const [showDetails, setShowDetails] = useState(false)
  const approveAll = () => {
    const newShipmentFields = { ...shipmentFields }
    Object.keys(newShipmentFields).forEach(key => {
      newShipmentFields[key].approved = true
    })
    setShipmentFields(newShipmentFields)
  }
  const approveAvailable = Object.values(shipmentFields).filter(shipment => !shipment.approved).length > 0

  useEffect(() => {
    if (shipmentsData) {
      setShipmentFields(shipmentsData)
    }
  }, [shipmentsData])

  const totalShipmentsCount = shipmentFields ? Object.keys(shipmentFields).length : 0
  const totalZeroPriceShipmentsCount = shipmentFields
    ? Object.values(shipmentFields).filter(shipment => parseFloat(shipment.price) === 0).length
    : 0
  const totalZeroPriceLabel = totalZeroPriceShipmentsCount ? ` (${totalZeroPriceShipmentsCount} uden beløb)` : ''
  const totalPrice = shipmentFields ? Object.values(shipmentFields).reduce((acc, shipment) => acc + parseFloat(shipment.price), 0) : 0

  const fieldId = (idKey, field) => `${idKey}-field-${field}`


  if (!shipmentFields) {
    return null
  }

  return (
    <fieldset className="resources-time-fields form-fieldset">
      <div className="row">
        <div className="col-sm-8 form-group">
          <label htmlFor={fieldId('shipments-count', 'shipments_count')}>Antal forsendelser</label>
          <input
            type="text"
            id={fieldId('shipments-count', 'shipments_count')}
            required
            className="form-control col-xs-12"
            value={`${totalShipmentsCount} forsendelser${totalZeroPriceLabel}`}
            disabled
          />
        </div>
        <div className="col-sm-4">
          <div className="form-group w-100">
            <label htmlFor={fieldId('total-price', 'total_price')}>Total</label>
            <div className="input-group">
              <input
                id={fieldId('total-price', 'total_price')}
                className="form-control"
                type="number"
                value={totalPrice.toFixed(2)}
                disabled
              />
              <div className="input-group-append">
                <span className="input-group-text">DKK</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 form-group">
          <div className="d-flex justify-content-between">
            <div className="form-check">
              <input
                type="checkbox"
                id={fieldId('show-details', 'show_details')}
                className="form-check-input mr-2"
                checked={showDetails}
                onChange={() => setShowDetails(!showDetails)}
              />
              <label htmlFor={fieldId('show-details', 'show_details')} className="form-check-label">
                Vis detaljer
              </label>
            </div>
            <button type="button" className="btn btn-primary" disabled={!approveAvailable || shipmentsLoading} onClick={approveAll}>
              Godkend alle
            </button>
          </div>
        </div>
      </div>
      {showDetails &&
        Object.keys(shipmentFields).map((key, index) => {
          const shipment = shipmentFields[key]
          const id = shipment.id
          const isDuplicate = Object.values(shipmentFields).filter(s => s.description === shipment.description).length > 1

          return (
            <div className="row" key={key}>
              <div className="col-sm-8 form-group">
                {index === 0 && <label htmlFor={fieldId(key, 'description')}>Beskrivelse</label>}
                <input
                  type="text"
                  id={fieldId(key, 'description')}
                  className="form-control col-xs-12"
                  value={shipment.description}
                  disabled
                />
              </div>
              <div className="col-sm-4">
                <div className="form-group w-100">
                  {index === 0 && <label htmlFor={fieldId(key, 'price')}>Beløb</label>}

                  <div className="d-flex">
                    <input
                      id={fieldId(key, 'price')}
                      className="form-control"
                      type="number"
                      step="0.01"
                      value={parseFloat(shipment.price).toFixed(2)}
                      disabled={shipment.approved}
                      onChange={e => {
                        const newShipmentFields = { ...shipmentFields }
                        newShipmentFields[key].price = e.target.value
                        setShipmentFields(newShipmentFields)
                      }}
                      name={shipment.approved ? '' : fieldNameFn(index, 'price')}
                    />
                    {id && isDuplicate && (
                      <RemoveFieldsButton className="ml-2 my-auto" handleRemove={() => confirm('Er du sikker?') && removeShipment(id)} />
                    )}
                  </div>
                </div>
              </div>
              {id && shipment.approved && <input type="hidden" name={fieldNameFn(index, 'id')} value={id} />}
              {id && shipment.approved && <input type="hidden" name={fieldNameFn(index, 'approved')} value="1" />}
              {id && shipment.approved && <input type="hidden" name={fieldNameFn(index, 'price')} value={shipment.price} />}
            </div>
          )
        })}
    </fieldset>
  )
}
